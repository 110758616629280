import { getProductsByIds } from '@nsf/catalog/repositories/ProductRepository.js'
import { setState } from '@nsf/core/Store.js'
import {
  getWatchdogsForUserEmail,
  postSubscribeWatchdog,
  removeAllPriceAlerts,
  removeAllStockAlerts,
  removePriceAlert,
  removePriceAlertHash,
  removeStockAlert,
  removeStockAlertHash,
} from '@nsf/layer-my-account/repositories/WatchdogRepository.js'

export default {
  namespaced: true,

  state: () => ({
    watchdogs: [],
    isWatchdogsInitialized: false,
    stockAlerts: [],
    priceAlerts: [],
    isErrorStockAlerts: false,
    isErrorPriceAlerts: false,
  }),

  mutations: {
    setState,

    setWatchdogs(
      state, payload,
    ) {
      state.watchdogs = payload
    },

    setStockAlerts(state, payload) {
      state.stockAlerts = payload
    },

    setPriceAlerts(state, payload) {
      state.priceAlerts = payload
    },

    addPriceAlert(state, payload) {
      state.priceAlerts.push(payload)
    },

    addStockAlert(state, payload) {
      state.stockAlerts.push(payload)
    },

    addWatchdog(
      state, payload,
    ) {
      state.watchdogs.push(payload)
    },

    removeWatchdog(
      state, { productId, type },
    ) {
      state.watchdogs = state.watchdogs.filter((item) => !((item.id === productId) && (item.type === type)))
      if (type === 'stockAlert') {
        state.stockAlerts = state.stockAlerts.filter((item) => item.id !== productId)
      }
      if (type === 'priceAlert') {
        state.priceAlerts = state.priceAlerts.filter((item) => item.id !== productId)
      }
    },

    removeAllWatchdogs(
      state, { type },
    ) {
      state.watchdogs = state.watchdogs.filter((item) => item.type !== type)
      if (type === 'stockAlert') {
        state.stockAlerts = []
      } else {
        state.priceAlerts = []
      }
    },
  },
  actions: {
    async loadWatchdogs(
      {
        commit, state,
      }, { email },
    ) {
      commit('setState', { isWatchdogsInitialized: false })

      const watchdogs = await getWatchdogsForUserEmail(email)
      if (watchdogs.isErrorPriceAlerts) {
        commit('setState', { isErrorPriceAlerts: true })
      }
      if (watchdogs.isErrorStockAlerts) {
        commit('setState', { isErrorStockAlerts: true })
      }

      if (watchdogs.stockAlerts.length === 0 && watchdogs.priceAlerts.length === 0) {
        commit('setState', { isWatchdogsInitialized: true })
        return
      }

      const stockAlertsIds = watchdogs.stockAlerts.map((item) => item.id)
      const priceAlertsIds = watchdogs.priceAlerts.map((item) => item.id)

      const [stockAlerts, priceAlerts] = await Promise.all([
        getProductsByIds(stockAlertsIds, { size: stockAlertsIds.length }),
        getProductsByIds(priceAlertsIds, { size: priceAlertsIds.length }),
      ])

      const stockAlertsProducts = stockAlerts.products.map((item) => {
        item.type = 'stockAlert'
        return item
      })

      const priceAlertsProducts = priceAlerts.products.map((item) => {
        item.type = 'priceAlert'
        item.threshold = watchdogs.priceAlerts.find((priceAlert) => priceAlert.id === item.id)?.threshold
        return item
      })

      const products = stockAlertsProducts.concat(priceAlertsProducts)

      commit('setWatchdogs', products)

      commit('setStockAlerts', stockAlertsProducts)
      commit('setPriceAlerts', priceAlertsProducts)

      commit('setState', { isWatchdogsInitialized: true })
    },

    async addWatchdog({ commit, state }, payload) {
      commit('setState', { isWatchdogsInitialized: false })
      const response = await postSubscribeWatchdog(payload)

      if (!response.errors) {
        payload.product.threshold = payload.priceAlertValue
        if (payload.isPriceAlert) {
          payload.product.type = 'priceAlert'
          commit('addPriceAlert', payload.product)
        } else {
          payload.product.type = 'stockAlert'
          commit('addStockAlert', payload.product)
        }
        commit('addWatchdog', payload.product)
      }
      commit('setState', { isWatchdogsInitialized: true })

      return response
    },

    async removeAllWatchdogs({ commit, state }, payload) {
      commit('setState', { isWatchdogsInitialized: false })

      let response
      if (payload.type === 'all') {
        response = await Promise.all([
          removeAllPriceAlerts(payload),
          removeAllStockAlerts(payload),
        ])

        commit('removeAllWatchdogs', { type: 'priceAlert' })
        commit('removeAllWatchdogs', { type: 'stockAlert' })

        commit('setState', { isWatchdogsInitialized: true })

        return response
      }

      if (payload.type === 'priceAlert') {
        response = await removeAllPriceAlerts(payload)
      } else {
        response = await removeAllStockAlerts(payload)
      }

      commit('removeAllWatchdogs', payload)
      commit('setState', { isWatchdogsInitialized: true })

      return response
    },

    async removeWatchdog({ commit, state }, payload) {
      if (payload.hash) {
        let response = null
        if (payload.type === 'priceAlert') {
          response = await removePriceAlertHash(payload.hash, true)
        } else {
          response = await removeStockAlertHash(payload.hash, true)
        }

        if (!response.errors) {
          commit(
            'removeWatchdog',
            payload,
          )
        }
        return response
      }

      const response = payload.type === 'stockAlert'
        ? await removeStockAlert(payload)
        : await removePriceAlert(payload)

      if (!response.errors) {
        commit(
          'removeWatchdog',
          payload,
        )
      }
      commit('setState', { isWatchdogsInitialized: true })
      return response
    },

    async removeAlertHashWithClientData({ commit, state }, payload) {
      let response = null
      if (payload.type === 'priceAlert') {
        response = await removePriceAlertHash(payload.hash, true)
      } else {
        response = await removeStockAlertHash(payload.hash, true)
      }

      return response
    },
  },
}
